import React from "react";
import "./styles.scss";

//IMAGES
import xxiiiBackground from "../../assets/images/xxiii_image.png";
import xxiiiLogo from "../../assets/logos/XXIII_logo.svg";
import creative from "../../assets/images/xxiii_cover.png";
import maison from "../../assets/images/xxiii-media_maison.png";
import gastro from "../../assets/images/xxiii-media_gastro.png";
import gears from "../../assets/images/xxiii-media_gears.png";
import allure from "../../assets/images/xxiii-media_allure-03.jpg";

const HomePage: React.FC = () => {
  return (
    <div className="home-page">
      <section id="header">
        <div className="banner">
          <img src={xxiiiBackground} />
          <div className="logo-wrapper">
            <img className="logo" src={xxiiiLogo} />
            <p className="tm">™</p>
          </div>
        </div>
      </section>
      <section id="link-list" className="padding-24">
        <h1>
          <strong>XXIII</strong>™ Elevate Your Brand
        </h1>
        <p>
          At XXIII™, we specialize in luxury real estate, culinary, automotive,
          and lifestyle, bringing your brand to life through stunning visuals.
        </p>
        <ul>
          <li>
            <a
              href="https://www.instagram.com/xxiii.creative/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={creative} />
              <strong>Creative</strong> | Everything Creative
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/xxiii.maison/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={maison} />
              <strong>Maison</strong> | Luxury Real Estate
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/xxiii.gastro/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={gastro} />
              <strong>Gastro</strong> | Art of Cuisine
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/xxiii.gears/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={gears} />
              <strong>Gears</strong> | Exotic & Tuned
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/xxiii.allure/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={allure} />
              <strong>Allure</strong>| The Art of Female Expression{" "}
            </a>
          </li>
        </ul>
      </section>
      <hr className="orange" />
      <footer>
        <p className="disclaimer">
          XXIII, LLC copyright © 2024 | All Rights Reserved
        </p>
        <div className="logo-wrapper">
          <img className="logo" src={xxiiiLogo} />
          <p className="tm">™</p>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
